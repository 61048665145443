body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mRow {
  /* height: 100vh; */
}
.mContainer {
  max-width: 100%;
  /* height: 100vh; */
}

.mLeft {
  background-color: white;
  text-align: center;
  height: 100vh;
}
@media (max-width: 768px) {
  .mLeft {
    margin: auto;
  }
}

.mRight {
  display: none;
}

@media (min-width: 768px) {
  .mRight {
    padding: 160px 3% 0 8%;
    color: white;
    text-align: left;
    background: linear-gradient(45deg, #7400ae 0%, #ff00e6 100%);
    display: block;
    height: 100vh;
  }
}
.mTitle {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 2.6rem;
  max-width: 600px;
}
.mPrice {
  font-size: 1.6rem;
  font-weight: 500;
}
.mList {
}

/* support */
.supportContainer {
  text-align: center;
  background: linear-gradient(45deg, #7400ae 0%, #ff00e6 100%);
  padding: 10px;
  color: white;
  font-weight: 600;
  padding: 30px;
}
.supportContainer p {
  margin: 0;
}
.supportContainer a {
  color: white;
  text-decoration: underline;
}
.supportContainer a:hover {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.separator {
  height: 2px;
  background-color: #eaeaea;
  margin-bottom: 12px;
}

.required {
  color: red;
  font-size: larger;
}

.previews {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.preview-image {
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
  display: inline-block;
}

.preview-image .npi {
  position: absolute;
  width: 100%;
  background: #ff2500;
  text-align: center;
  font-weight: 400;
}

.preview-image .npi a {
  color: #fff !important;
}

.preview-image img {
  width: 100%;
  transition: all 0.3s ease;
  height: auto;
}

.preview-image.isMulti {
  width: 35%;
  margin-right: 8px;
}

.policy-list,
.policy-list > li {
  list-style-type: lower-alpha;
}

.policy-list {
  margin-top: 12px;
}

.policy-list ul {
  margin-top: 8px;
  margin-bottom: 8px;
}

.policy-list ul,
.policy-list ul > li {
  padding-left: 12px;
  list-style-type: decimal;
}

.preview-video {
  max-width: 100%;
}

.content-meta {
  font-size: 15px;
}

.draggable-option {
  width: 100%;
  display: block;
  padding: 10px;
}

.draggable-option > div:first-child {
  flex: 1;
}

.content-item {
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #672e91;
}

.content-title {
  flex: 1;
  display: inline-block;
}

.content-icon {
  font-size: 30px;
  margin-right: 16px;
}

.icon-delete {
  display: none;
}

/* .content-item:hover .icon-delete {
  display: inline;
} */

.message {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  margin-right: 16px;
  top: 16px;
  z-index: 9999;
}

.nav-menu {
  background-color: #333a40;
}

.nav-menu .navbar-nav {
  padding: 4px 16px 16px 16px;
}

#vimeo-import label {
  margin-bottom: 0;
  font-weight: bold;
  color: #676767;
  font-size: 12px;
  text-transform: uppercase;
}

#global-ad-modal .form-section {
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

#global-ad-modal h5 {
  text-align: center;
  font-weight: bold;
}

#global-ad-modal h5 ion-icon {
  display: none;
}

#vimeo-thumbnail-tool img {
  width: 120px;
  height: 80px;
  object-fit: cover;
}

.error {
  font-size: 12px;
}

.bunny-video-item {
  padding: 0.75rem !important;
}

.bunny-video-item img {
  width: 120px;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.bunny-video-item > .media {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bunny-video-item:hover {
  background-color: #f5f5f5;
}
