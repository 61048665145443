// Override default variables before the import
$theme-colors: (
  "primary": #672e91
);

$font-family-base: "Montserrat", sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$btn-light-border: 1px #000 solid;

$headings-font-weight: 400;
$light: #ebebeb;

$navbar-toggler-border-radius: 0;
$navbar-dark-toggler-border-color: bg-dark;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
